import React, { createRef, useState } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Axios from "axios";
import { addExercise, deleteExercise } from "../redux";
import { ClientUrl } from "../components/url";
import styles from "../styles/firstscreen.module.scss";

function FirstScreen(props) {
	const exercises = props.exercise;
	const [username, setUserName] = useState("");
	const [showInput, setShowInput] = useState(false);

	//state
	const [newExercise, setExercise] = useState({
		routineName: "",
		exerciseName: "none",
		exerciseTime: 0,
		repetitionTime: 0,
		recoveryTime: 0,
		destinationRound: 1,
		count: 5000,
		visibility: "public",
		playAudio: false,
	});
	//default exercises
	let storedExercises = exercises
	let displayExercise = storedExercises
	//refs
	const myRef = createRef();

	const deleteExercise = (e) => {
		e.preventDefault();
		console.log(e.target.id)
		props.deleteExercise(e.target.id);
		//send exercise to the backend
		Axios.defaults.withCredentials = true;
	};

	const deleteAll = (e) => {
		e.preventDefault();

		displayExercise = [];
		window.localStorage.setItem("exercises", JSON.stringify(displayExercise));
	};

	//edit exercise function

	const editExercise = (e) => {
		e.preventDefault();
		setShowInput(true);
	};

	function handleChange(e) {
		const { name, value } = e.target;
		setExercise((user) => ({ ...user, [name]: value }));
		console.log(name, value);
	}

	return (
		<div className="container mx-auto">
			<div className={`${styles.topHeader} ${styles.dontShow}`}>
				<div className={`${styles.profile}`}>
					<img
						src={`${ClientUrl}/profile.png`}
						alt="profile"
						className={styles.profileImage}
					/>
					<span className={`${styles.profileText}`}>Hi, {username}</span>
				</div>
				<Link to="/">
					<div className={`${styles.brandLogo}`}>
						<img
							src={`${ClientUrl}/assymexlogo.svg`}
							alt="asymmexlogo"
							className={styles.asymmexLogo}
						/>
					</div>
				</Link>

				<div className={`${styles.logout}`}>
					{/* <span logout={`${styles.logoutText}`}>Logout</span>
  <img src={`${ClientUrl}/log_out.svg`} alt="logout"  className= {`${styles.logoutImage}`} /> */}
				</div>
			</div>

			<div className={`${styles.topSubnav}`}>
				<div className={`${styles.workout}`}>
					<p className={`${styles.workoutText}`}>My Workouts</p>
				</div>
				<div className={`${styles.featured}`}>
					<p className={`${styles.featuredText}`}>Featured</p>
				</div>
			</div>

			{displayExercise.length === 0 && (
				<div>
					<div className={`${styles.icRun} mx-28`}>
						<img src={`${ClientUrl}/ic_Run.svg`} alt="run" />
					</div>

					<div className={`${styles.infoDiv} `}>
						<p className={`${styles.infoText}`}>
							You have not creatd any workouts yet. Please click plus to get
							started with a new workout
						</p>
					</div>
				</div>
			)}

			{displayExercise.length !== 0 &&
				displayExercise.map((exercise) => (
					<div
						className={`${styles.exerciseWrapper}`}
						key={exercise.key}
						ref={myRef}
						id={exercise.key}>
						<div className={`${styles.exercisePWrapper}`}>
							<div className={`${styles.miniList}`}>
								<p className={`${styles.exerciseNameText}`}>
									{" "}
									{exercise.exercise}
								</p>{" "}
								<ul className={`${styles.list}`}>
									<li className={`${styles.edit}`}>
										<img
											src={`${ClientUrl}/edit.svg`}
											alt="edit"
											className={`${styles.editImage}`}
											onClick={editExercise}
										/>
									</li>
									<span
										className={`${styles.delete}`}
										onClick={deleteExercise}
										id={exercise.key}>
										{" "}
										<img
											src={`${ClientUrl}/trash_full.svg`}
											alt="trash"
											className={`${styles.deleteImage} `}
											id={exercise.key}
										/>
									</span>
								</ul>
							</div>
						</div>
						<p className={`${styles.timer}`}>
							<span className={`${styles.duration}`}>Duration: </span>{" "}
							<span className={`${styles.bold}`}>
								{parseInt(exercise.duration)}
							</span>{" "}
							<span className={`${styles.duration}`}> Recovery: </span>
							<span className={`${styles.bold}`}>
								{parseInt(exercise.recoveryTime)}
							</span>
							<span className={`${styles.duration}`}> Repetition: </span>
							<span className={`${styles.bold}`}>
								{parseInt(exercise.repetition)}
							</span>
						</p>
						<div className={`editDiv`} id="edit-div" style={{display: "none"}}>
							<input
								className={`${styles.userName} mx-4 `}
								name="routineName"
								id="routine"
								placeholder="routine name"
								list="routines"
								onChange={handleChange}
							/>
							<input
								type="text"
								name="exercise-name"
								required
								className={`${styles.userName}  mx-4`}
								placeholder="exercise-name"
							/>
							<input
								type="text"
								name="duration"
								required
								className={`${styles.userName}  mx-4`}
								placeholder="duration"
							/>
							<input
								type="text"
								name="repetitionTime"
								placeholder="repetition"
								id="repetition"
								list="repetition-duration"
								onChange={handleChange}
								className={`${styles.userName} mx-4`}
							/>
							<input
								type="number"
								name="recoveryTime"
								placeholder="recovery time"
								id="recovery-duration"
								list="recovery-list"
								onChange={handleChange}
								className={`${styles.userName} mx-4`}
							/>
							<input
								type="text"
								name="destinationRound"
								placeholder="Select circuit number"
								id="round"
								list="destination-rounds"
								onChange={handleChange}
								className={`${styles.userName} mx-4`}
							/>
							<select
								name="visiility"
								placeholder="Select visibility"
								id="round"
								list="destination-rounds"
								onChange={handleChange}
								className={`${styles.userName} mx-4`}>
								<option value="public">Public</option>
								<option value="private">Private</option>
							</select>
							<button
								type="submit"
								className={`${styles.submitButton}`}> update
							</button>
						</div>
					</div>
				))}

			<div className={`${styles.addDiv}`} id="addDiv">
				{displayExercise.length !== 0 && (
					<>
						<img
							src={`${ClientUrl}/trash_grey.svg`}
							alt="trash"
							width="18px"
							height="20px"
							className={`${styles.deleteAllImage}`}
						/>
						<p className={`${styles.deleteAll}`} onClick={deleteAll}>
							Delete all
						</p>
					</>
				)}

				<p className={`${styles.addText}`}>Add exercise</p>
				<Link to="/build">
					<button className={`${styles.submitButton}`}>
						<img
							src={`${ClientUrl}/plus.svg`}
							alt="add"
							className={`${styles.imageButton} mx-3 my-3`}
						/>
					</button>
				</Link>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		exercise: state.exercises
	}
}

const mapDispatchToProps = (dispatch) => {
	return {

		deleteExercise: (newExercise) => dispatch(deleteExercise(newExercise)),
		editExercise: (editedExercise) => dispatch(editedExercise)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstScreen);