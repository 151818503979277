import { React, useState } from "react";
import { Link } from "react-router-dom";
import { ClientUrl, ServerUrl } from "../components/url";
import styles from "../styles/login.module.scss";

function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
 
	const emailError = document.querySelector(".email.error");
	const passwordError = document.querySelector(".password.error");


	const handleEmailInput = (e) => {
		e.preventDefault();
		setEmail((email) => e.target.value);
	};

	const handlePasswordInput = (e) => {
		e.preventDefault();
		setPassword((password) => e.target.value);
	};

	const login = async (e) => {
		e.preventDefault();
		// reset errors
		emailError.textContent = "";
		passwordError.textContent = "";

		try {
			const res = await fetch(`${ServerUrl}/login`, {
				method: "POST",
				body: JSON.stringify({ email, password }),
				credentials: "include",
				headers: { "Content-Type": "application/json" },
			});
			const data = await res.json();
			console.log(data);
			if (data.errors) {
				emailError.textContent = data.errors.email;
				passwordError.textContent = data.errors.password;
			}
			if (data.user) {
				let token = await data.data;
				console.log(data.user);
				localStorage.setItem("token", token);
				localStorage.setItem("user", JSON.stringify(data.user));

				await window.location.assign("/build");
			}
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<div className="container mx-auto">
			<div className={`${styles.header} ${styles.dontShow}`}>
				<Link to="/">
					<div className={`${styles.backDiv}`}>
						<img src={`${ClientUrl}/back.svg`} alt="back" className={`${styles.backImage} my-2.5 mx-2.5`}/>
					</div>
				</Link>
				<Link to="/">
					<div className={`${styles.brandLogo}`}>
						<img src={`${ClientUrl}/assymexlogo.svg`} alt="asymmexlogo" />
					</div>
				</Link>
			</div>
			<div className={`${styles.helloDiv}  mx-7`}>
				<p className={`${styles.helloParagraph}`}>Hello,</p>
				<p className={`${styles.openAccount}`}>
					<Link to="/signup" className={`${styles.loginText}`}>Open your account</Link> to start exercising
				</p>
			</div>
			<div className={`${styles.legExercise}  mx-24`}>
				<img src={`${ClientUrl}/legexercise.svg`} alt="leg exercise" />
			</div>
			<form action="/login">
				<input
					type="text"
					name="email"
					required
					onChange={handleEmailInput}
					className={`${styles.userEmail} mx-7`}
					placeholder="email"
				/>
				<div className="email error">
				</div>
				<input
					type="password"
					name="password"
					required
					onChange={handlePasswordInput}
					className={`${styles.userPassword} mx-7`}
					placeholder="password"
				/>
				<div className="password error">
				</div>
				<p>
					{" "}
					<a
						className={`${styles.forgotPassword}`}
						href="/reset-password/request">
						{" "}
						Forgot password?
					</a>{" "}
				</p>
				<div className={`${styles.submitParagraph} ${styles.dontShow}`}>
					<p className={`${styles.letsContinue}`}>Let's continue</p>
					<button
						type="submit"
						onClick={login}
						className={`${styles.submitButton}`}>
						<img src={`${ClientUrl}/Vector.svg`} alt="login" className={`${styles.imageButton} mx-3 my-3`}/>
					</button>
				</div>
			</form>
		</div>
	);
}

export default Login;
