import React, { useState } from "react";
import { Link } from "react-router-dom";
import async from "async";
import { ClientUrl } from "../components/url";
import styles from "../styles/start.module.scss";
import { connect } from "react-redux";
import { addExercise } from "../redux";

function Start(props) {
	//default exercises
	let storedExercises = props.exercise;
	let displayExercise = storedExercises;

	const [check, setCheck] = useState(true);
	const [exerciseNumber, setExerciseNumber] = useState(0);
	const [isPaused, setIsPaused] = useState(false);

	let totalTimeArray = [];
	let totalTimeRemaining;
	// setBuildedExercise(displayExercise);
	displayExercise.map((exercise) => {
		totalTimeArray.push(
			exercise.duration,
			exercise.recoveryTime,
			exercise.repetition
		);
	});

	let sum = totalTimeArray.reduce((total, number) => {
		return (totalTimeRemaining = total + number);
	}, 0);
	let playPauseImageUrl = `${ClientUrl}/play_arrow.svg`;

	const getReadyAudio = new Audio(
		"https://storageapi.fleek.co/bwakedavis-team-bucket/start-exercise.mp3"
	);
	const startSoundAudio = new Audio(
		"https://storageapi.fleek.co/bwakedavis-team-bucket/Start-Stop_Bell.mp3"
	);
	const holdSoundAudio = new Audio(
		"https://storageapi.fleek.co/bwakedavis-team-bucket/Hold_Bell.mp3"
	);
	const repetitionSoundAudio = new Audio(
		"https://storageapi.fleek.co/bwakedavis-team-bucket/Repetitions_Bell.mp3"
	);

	const finishingStepsSoundAudio = new Audio(
		"https://storageapi.fleek.co/bwakedavis-team-bucket/finishing-countdown-steps.mp3"
	);
	const singleExerciseAchievementSound = new Audio(
		"https://storageapi.fleek.co/bwakedavis-team-bucket/single-exercise-achievement.mp3"
	);
	const wholeExerciseAchevementSound = new Audio(
		"https://storageapi.fleek.co/bwakedavis-team-bucket/whole-exercise-achievement.mp3"
	);

	const startStopEvent = (e) => {
		e.preventDefault();
		setCheck(!check);
		setIsPaused(!isPaused);

		if (check === true) {
			playPauseImageUrl = `${ClientUrl}/pause.svg`;
			holdSoundAudio.play();
			reduce(exerciseNumber);
		} else {
			playPauseImageUrl = `${ClientUrl}/play_arrow.svg`;
		}
	};

	const countDown = (time) => {
		return parseInt(time) <= 0 ? 0 : parseInt(time) - 1;
	};
	const reduce = (exerciseNumber) => {
		let displayTimeDom = document.querySelector("#exercise-dom");
		let RemainingTime = document.querySelector("#totalRemainingTime");
		let arr = displayExercise;
		let utterance;

		async.eachOfSeries(
			arr,
			(tr, index, next) => {
				startSoundAudio.play();
				utterance = new SpeechSynthesisUtterance(`Get Ready`);
				speechSynthesis.speak(utterance);
				setTimeout(() => {
					utterance = new SpeechSynthesisUtterance(
						` Next ${tr.exercise} , ${tr.duration} seconds, `
					);
					speechSynthesis.speak(utterance);
					displayTimeDom.textContent = tr.duration;
					setTimeout(() => {
						let timeInterval = setInterval(() => {
							if (!isPaused) {
								displayTimeDom.textContent = countDown(
									displayTimeDom.textContent
								);
								// parseInt(displayTimeDom.textContent) - 1;
								tr.duration = countDown(displayTimeDom.textContent);
								//  parseInt(displayTimeDom.textContent) - 1;
								RemainingTime.textContent = countDown(
									RemainingTime.textContent
								);
								// parseInt(RemainingTime.textContent) - 1;
								if (parseInt(displayTimeDom.textContent) === 5) {
									finishingStepsSoundAudio.play();
								}
								if (parseInt(displayTimeDom.textContent) === 2) {
									singleExerciseAchievementSound.play();
								}
								if (parseInt(displayTimeDom.textContent) <= 0) {
									clearInterval(timeInterval);
									utterance = new SpeechSynthesisUtterance(` Take a rest,`);
									speechSynthesis.speak(utterance);
									displayTimeDom.textContent = tr.recoveryTime;

									setTimeout(() => {
										let timeInterval2 = setInterval(() => {
											if (!isPaused) {
												displayTimeDom.textContent = countDown(
													displayTimeDom.textContent
												);
												// parseInt(displayTimeDom.textContent) - 1;
												tr.duration = countDown(displayTimeDom.textContent);
												//  parseInt(displayTimeDom.textContent) - 1;
												RemainingTime.textContent = countDown(
													RemainingTime.textContent
												);
												// displayTimeDom.textContent =countDown(displayTimeDom.textContent)
												// 	// parseInt(displayTimeDom.textContent) - 1;
												// tr.recoveryTime = parseInt(displayTimeDom.textContent) - 1;
												// RemainingTime.textContent =
												// 	parseInt(RemainingTime.textContent) - 1;
												if (parseInt(displayTimeDom.textContent) === 3) {
													utterance = new SpeechSynthesisUtterance(` Three`);
												}
												if (parseInt(displayTimeDom.textContent) === 2) {
													utterance = new SpeechSynthesisUtterance(` Two`);
												}
												if (parseInt(displayTimeDom.textContent) === 1) {
													utterance = new SpeechSynthesisUtterance(` Three`);
												}
												if (parseInt(displayTimeDom.textContent) <= 0) {
													clearInterval(timeInterval2);
													utterance = new SpeechSynthesisUtterance(
														` Next ${tr.repetition} seconds, repetitions ${tr.exercise}`
													);
													speechSynthesis.speak(utterance);
													displayTimeDom.textContent = tr.repetition;

													setTimeout(() => {
														let timeInterval3 = setInterval(() => {
															if (!isPaused) {
																displayTimeDom.textContent = countDown(
																	displayTimeDom.textContent
																);
																// parseInt(displayTimeDom.textContent) - 1;
																tr.duration = countDown(
																	displayTimeDom.textContent
																);
																//  parseInt(displayTimeDom.textContent) - 1;
																RemainingTime.textContent = countDown(
																	RemainingTime.textContent
																);
																// displayTimeDom.textContent =
																// 	parseInt(displayTimeDom.textContent) - 1;
																// tr.repetition =
																// 	parseInt(displayTimeDom.textContent) - 1;
																// RemainingTime.textContent =
																// 	parseInt(RemainingTime.textContent) - 1;
																if (
																	parseInt(displayTimeDom.textContent) === 5
																) {
																	finishingStepsSoundAudio.play();
																}
																if (
																	parseInt(displayTimeDom.textContent) === 2
																) {
																	singleExerciseAchievementSound.play();
																}
																if (parseInt(displayTimeDom.textContent) <= 0) {
																	clearInterval(timeInterval3);
																	setExerciseNumber({
																		exerciseNumber: exerciseNumber + 1,
																	});
																	next();
																}
															} else {
																clearInterval(timeInterval3);
																displayTimeDom.textContent = tr.repetition;
															}
														}, 1000);
													}, 3000);
												}
											} else {
												clearInterval(timeInterval2);
												displayTimeDom.textContent = tr.recoveryTime;
											}
										}, 1000);
									}, 3000);
								}
							} else {
								clearInterval(timeInterval);
								displayTimeDom.textContent = tr.duration;
							}
						}, 1000);
					}, 3000);
				}, 10000);
			},
			function () {
				//Do anything after complete of for loop
				console.log("done");
			}
		);
	};

	const back = (e) => {
		e.preventDefault();
		let displayTimeDom = document.querySelector("#exercise-dom");
		if (exerciseNumber <= 0) {
			exerciseNumber({ exerciseNumber: 0 });
		} else {
			exerciseNumber({ exerciseNumber: exerciseNumber - 1 }, () => {
				displayTimeDom.textContent = displayExercise[exerciseNumber].duration;
			});
		}
	};

	const forward = (e) => {
		e.preventDefault();
		let displayTimeDom = document.querySelector("#exercise-dom");
		if (exerciseNumber <= displayExercise.length - 1) {
			setExerciseNumber({ exerciseNumber: exerciseNumber + 1 }, () => {
				displayTimeDom.textContent = displayExercise[exerciseNumber].duration;
			});
		} else {
			setExerciseNumber({ exerciseNumber: displayExercise.length - 1 });
		}
	};

	const back10 = (e) => {
		e.preventDefault();
		let displayTimeDom = document.querySelector("#exercise-dom");
		let RemainingTime = document.querySelector("#totalRemainingTime");
		let timeTypeText = document.querySelector("#timeTypeText");
		displayTimeDom.textContent = parseInt(displayTimeDom.textContent) + 10;
		RemainingTime.textContent = parseInt(RemainingTime.textContent) + 10;
		totalTimeRemaining += 10;
		if (timeTypeText.textContent === "Duration") {
			displayExercise[exerciseNumber].duration += 10;
		}
	};

	const forward10 = (e) => {
		e.preventDefault();
		let displayTimeDom = document.querySelector("#exercise-dom");
		let RemainingTime = document.querySelector("#totalRemainingTime");
		let timeTypeText = document.querySelector("#timeTypeText");
		displayTimeDom.textContent = parseInt(displayTimeDom.textContent) - 10;
		RemainingTime.textContent = parseInt(RemainingTime.textContent) - 10;
		totalTimeRemaining -= 10;
		if (timeTypeText.textContent === "Duration") {
			displayExercise[exerciseNumber].duration += 10;
		}
	};

	return (
		<div className="container mx-auto">
			<main>
				<div className={`${styles.header} ${styles.dontShow}`}>
					<Link to="/build">
						<div className={`${styles.backDiv}`}>
							<img
								src={`${ClientUrl}/back.svg`}
								alt="back"
								className={`${styles.backImage} my-2.5 mx-2.5`}
							/>
						</div>
					</Link>

					<Link to="/">
						<div className={`${styles.brandLogo}`}>
							<img
								src={`${ClientUrl}/assymexlogo.svg`}
								alt="asymmexlogo"
								className={`${styles.brandImage}`}
							/>
						</div>
					</Link>
					<div className={`${styles.heartDiv}`}>
						<img
							src={`${ClientUrl}/heart_outline.svg`}
							alt="asymmexlogo"
							className={`${styles.heartImage}`}
						/>
					</div>
					<div className={`${styles.settingsDiv}`}>
						<img
							src={`${ClientUrl}/settings_filled.svg`}
							alt="asymmexlogo"
							className={`${styles.settingsImage}`}
						/>
					</div>
				</div>

				<div className={`${styles.currentExerciseDiv} mx-20`}>
					<p className={`${styles.currentExerciseText}`} id="exercise-name">
						{displayExercise[exerciseNumber].exercise}
					</p>
					<p className={`${styles.timeCountText}`} id="exercise-dom">
						{displayExercise[exerciseNumber].duration}
					</p>
				</div>

				<div className={`${styles.controls}`}>
					<span className={`${styles.back10}`} id="back10" onClick={back10}>
						<img src={`${ClientUrl}/back10.svg`} alt="back10" />
					</span>
					<span className={`${styles.back}`} onClick={back}>
						<img
							src={`${ClientUrl}/backplay.svg`}
							alt="back"
							className={`${styles.backImage} my-1 mx-0.1`}
						/>
					</span>
					<span className={`${styles.pause}`} onClick={startStopEvent}>
						<img
							src={playPauseImageUrl}
							alt="pause"
							id="playPause"
							className="mx-3 my-3"
						/>
					</span>
					<span
						className={`${styles.forward}`}
						id="forward10"
						onClick={forward}>
						<img
							src={`${ClientUrl}/forward.svg`}
							alt="forward"
							className={`${styles.backImage} my-1 mx-0.5`}
						/>
					</span>
					<span className={`${styles.forward10}`} onClick={forward10}>
						<img src={`${ClientUrl}/forward10.svg`} alt="forward10" />
					</span>
				</div>

				<div className={`${styles.imageWrapper} mx-16`}>
					{/* <ReactPlayer
							width="230px"
							height="230px"
							url="https://storageapi.fleek.co/bwakedavis-team-bucket/starterExercise.mp4"
							controls
							playing
							playbackRate
							className={`${styles.video}`}
						/> */}
					<img
						src={`${ClientUrl}/exerciseimage.svg`}
						alt="leg exercise"
						className={`${styles.image}`}
					/>
				</div>

				<p className={`${styles.totalRemainingTimeText}`}>
					Total Remaining:{" "}
					<span
						className={`${styles.totalRemainingTime}`}
						id="totalRemainingTime">
						{" "}
						{totalTimeRemaining}
					</span>
					<span>s</span>
				</p>

				<div className={`${styles.nextExerciseDiv}`} onClick={forward}>
					<p className={`${styles.nextPWrapper}`}>
						<span className={`${styles.next}`}>NEXT:</span>
						<span className={`${styles.nextExercise}`}>
							{displayExercise[exerciseNumber + 1].exercise}
						</span>
					</p>
				</div>
			</main>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		exercise: state.exercises
	}
}

const mapDispatchToProps = (dispatch) => {
	return {

		addExercise: dispatch(addExercise)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Start)