import React from "react";
import { Link } from "react-router-dom";
import styles from "../styles/build.module.scss";
import { ClientUrl } from "../components/url";
export default function About(props) {
  return (
    <div className="container mx-auto">
      			<div className={`${styles.header} ${styles.dontShow}`}>
				<Link to="/">
					<div className={`${styles.backDiv} mx-7`}>
						<img src={`${ClientUrl}/back.svg`} alt="back" className={`${styles.backImage} my-2.5 mx-2.5`}/>
					</div>
				</Link>
				<Link to="/">
					<div className={`${styles.brandLogo} ml-40`}>
						<img src={`${ClientUrl}/assymexlogo.svg`} alt="asymmexlogo" />
					</div>
				</Link>
			</div>
      <h2>We bring all you need for your workout in one place</h2>
	  <div>
        </div>
	  
    </div>
  );
}


