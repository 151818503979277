import { React, useState } from "react";
import { Link } from "react-router-dom";
import {ClientUrl, ServerUrl} from '../components/url';

import styles from "../styles/resetpasswordrequest.module.scss";

function ResetPassword() {
	const [password, setpassword] = useState("");

	const handlePasswordInput = (e) => {
		e.preventDefault();
		setpassword((password) => e.target.value);
	};

	const submitRequest = async () => {
		fetch(`${ServerUrl}/password-reset`, {
			method: "POST",
			body: JSON.stringify({ password }),
			credentials: "include",
			headers: { "Content-Type": "application/json" },
		})
			.then(() => {
				console.log("sent");
				window.location.assign("/reset-password/confirmation");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="container mx-auto">
			<div className={`${styles.header} ${styles.dontShow}`}>
				<Link to="/">
					<div className={`${styles.backDiv}`}>
						<img src={`${ClientUrl}/back.svg`} alt="back" />
					</div>
				</Link>
				<Link to="/">
					<div className={`${styles.brandLogo}`}>
						<img src={`${ClientUrl}/assymexlogo.svg`} alt="asymmexlogo" />
					</div>
				</Link>
			</div>
			<div className={`${styles.helloDiv}`}>
				<p className={`${styles.helloParagraph}`}>password,</p>
				<p className={`${styles.openAccount}`}>
					Enter your new password
				</p>
			</div>
			<div className={`${styles.legExercise}`}>
				<img src={`${ClientUrl}/legexercise.svg`} alt="" />
			</div>
			<form action="/reset-password-request">
				<input
					type="text"
					name="email"
					required
					onChange={handlePasswordInput}
					className={`${styles.userEmail}`}
					placeholder="enter password"
				/>
				<input
					type="password"
					name="password"
					required
					onChange={handlePasswordInput}
					className={`${styles.userEmail}`}
					placeholder="email"
				/>


				<div className={`${styles.submitParagraph}`}>
						<p className={`${styles.letsContinue}`}>Let's continue</p>
						<button type="submit" onClick={submitRequest} className={`${styles.submitButton}`}>
						<img src={`${ClientUrl}/Vector.svg`} alt="go" className={`${styles.imageButton} mx-3 my-3`}/>
						</button>
					</div>
			</form>
		</div>
	);
}

export default ResetPassword;
