import { React, useState } from "react";
import { Link } from "react-router-dom";
import {ClientUrl, ServerUrl} from '../components/url';
import styles from "../styles/resetpasswordrequest.module.scss";

function ResetPasswordRequest() {
	const [email, setEmail] = useState("");


	const handleEmailInput = (e) => {
		e.preventDefault();
		setEmail((email) => e.target.value);
	};

	const submitRequest = async () => {
		fetch(`${ServerUrl}/reset-password-request`, {
			method: "POST",
			body: JSON.stringify({ email }),
			credentials: "include",
			headers: { "Content-Type": "application/json" },
		})
			.then(() => {
				console.log("sent");
				window.location.assign("/reset-password/confirmation");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="container mx-auto">
			<div className={`${styles.header} ${styles.dontShow}`}>
				<Link to="/">
					<div className={`${styles.backDiv}`}>
						<img src={`${ClientUrl}/back.svg`} alt="back" />
					</div>
				</Link>
				<Link to="/">
					<div className={`${styles.brandLogo}`}>
						<img src={`${ClientUrl}/assymexlogo.svg`} alt="asymmexlogo" />
					</div>
				</Link>
			</div>
			<div className={`${styles.helloDiv}`}>
				<p className={`${styles.helloParagraph}`}>Email,</p>
				<p className={`${styles.openAccount}`}>
				Enter your email to reset your password
				</p>
			</div>
			<div className={`${styles.legExercise}`}>
				<img src={`${ClientUrl}/legexercise.svg`} alt="" />
			</div>
			<form action="/reset-password-request">
				<input
					type="text"
					name="email"
					required
					onChange={handleEmailInput}
					className={`${styles.userEmail}`}
					placeholder="email"
				/>

				<div className={`${styles.submitParagraph} `}>
					<p className={`${styles.letsContinue}`}>Request link</p>
					<button
						type="submit"
						onClick={submitRequest}
						className={`${styles.submitButton}`}>
						&gt;
					</button>
				</div>
			</form>
		</div>
	);
}

export default ResetPasswordRequest;
