import React, { useState,} from 'react';
import { Link } from 'react-router-dom';
import {ClientUrl, ServerUrl} from '../components/url';
import styles from "../styles/signup.module.scss";

function Signup() {
	const [user, setUser] = useState({
		firstname: '',
		lastname: '',
        username: '',
		email: '',
        password: ''
    });
 
	function handleChange(e) {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
    }

 
	const emailError = document.querySelector(".email-error");
	const passwordError = document.querySelector(".password-error");

	const signup = async (e) => {
		e.preventDefault();
		console.log(user)
		// reset errors
		emailError.textContent = "";
		passwordError.textContent = "";
		try {
			const res = await fetch(`${ServerUrl}/signup`, {
				method: "POST",
				body: JSON.stringify(user),
				credentials: "include",
				headers: { "Content-Type": "application/json" },
			});
			const data = await res.json();
			if (data.errors) {
				emailError.textContent = data.errors.email;
				passwordError.textContent = data.errors.password;
			}
			if (data.user) {
				let token = await data.data;
				console.log(data.user);
				localStorage.setItem("token", token);
				 window.location.assign("/build");
			}
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<div className="container mx-auto">
			<div className={`${styles.header} ${styles.dontShow}`}>
				<Link to="/">
					<div className={`${styles.backDiv} mx-7`}>
						<img src={`${ClientUrl}/back.svg`} alt="back" className={`${styles.backImage} my-2.5 mx-2.5`}/>
					</div>
				</Link>
				<Link to="/">
					<div className={`${styles.brandLogo} ml-40`}>
						<img src={`${ClientUrl}/assymexlogo.svg`} alt="asymmexlogo" />
					</div>
				</Link>
			</div>
			<div className={`${styles.helloDiv} mx-7`}>
				<p className={`${styles.helloParagraph}`}>Hello,</p>
				<p className={`${styles.openAccount}`}>
					<Link to="/login" className={`${styles.loginText}`}>Have an account</Link> to start exercising
				</p>
			</div>
			<div className={`${styles.legExercise} mx-24`}>
				<img src={`${ClientUrl}/legexercise.svg`} alt="" />
			</div>
			<div className="form-group">
				<form action="/signup" onSubmit= {signup}>
					<input
						type="text"
						name="firstname"
						required
						className={`${styles.userName} mx-7`}
						onChange={handleChange}
						placeholder="firstname"
					/>
										<input
						type="text"
						name="lastname"
						required
						className={`${styles.userEmail} mx-7`}
						onChange={handleChange}
						placeholder="lastname"
					/>
										<input
						type="text"
						name="username"
						required
						className={`${styles.userEmail} mx-7`}
						onChange={handleChange}
						placeholder="username"
					/>
					<div className="username-error"></div>
					<input
						type="text"
						name="email"
						required
						onChange={handleChange}
						placeholder="email"
						className={`${styles.userEmail} mx-7`}
					/>
					<div className="email-error"></div>
					<input
						type="password"
						name="password"
						required
						onChange={handleChange}
						placeholder="password"
						className={`${styles.userPassword} mx-7`}
			 		/>
					<div className="password-error"></div>
					<div className={`${styles.submitParagraph}`} >
						<p className={`${styles.letsContinue}`}>Let's continue</p>
						<button type="submit" className={`${styles.submitButton}`}>
						<img src={`${ClientUrl}/Vector.svg`} alt="continue" className={`${styles.imageButton} mx-3 my-3`}/>
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Signup;
