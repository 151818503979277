import { React, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./redux/store";
import BuildComponent from "./pages/Build";
import HomeComponent from "./pages/Home";
import AboutComponent from "./pages/About";
import StartComponent from "./pages/Start";
import SignupComp from "./pages/Signup";
import LoginComp from "./pages/Login";
import ResetPasswordRequestComp from "./pages/ResetPasswordRequest";
import PasswordResentConfirmationComp from "./pages/PasswordResetConfirmation";
import ResetPasswordComp from "./pages/ResetPassword";
import FirstScreenComponent from "./pages/FirstScreen";
import { ClientUrl, ServerUrl } from "./components/url";
//5m45f4o5Tm9t!#8iu&Lf
//server:mail.gmx.com or smtp.gmx.com
//port:467 or 587

export default function App() {
	return (
		<Provider store={store}>
			<Nav />
		</Provider>
	);
}

function Nav() {


	useEffect(() => {
	});

	return (
		<main>
			<Router>
				<div>
					<nav className="main-navbar">
						<div className="site-title">
							<a href="/">
								<img src={`${ClientUrl}/logo.png`} alt="logo" />
							</a>
						</div>
						<ul>
							<li>
								<a href="/build">Build</a>
							</li>
							{/* <li><a href="/start">Start</a></li> */}
							<li>
								<a href="/" className="username">
									{" "}
								</a>
							</li>
							<li>
								<a href="/signup" className="signup">
									Signup{" "}
								</a>
							</li>
							<li>
								<a href="/login" className="login">
									Login{" "}
								</a>
							</li>
							<li>
								<a
									href="/logout"
									className="logout"
									onClick={(e) => {
										e.preventDefault();
										fetch(`${ServerUrl}/logout`, {
											mode: "no-cors",
											method: "GET",
											credentials: "include",
											headers: { "Content-Type": "application/json" },
										}).then(() => {
											window.location.assign("/");
										});
									}}>
									{" "}
								</a>
							</li>
						</ul>
					</nav>
					<Switch>
						<Route path="/about">
							<About />
						</Route>
						<Route path="/build">
							<Build />
						</Route>
						<Route path="/start">
							<Start />
						</Route>
						<Route path="/signup">
							<Signup />
						</Route>
						<Route path="/login">
							<Login />
						</Route>
						<Route path="/reset-password/request">
							<ResetPasswordRequest />
						</Route>
						<Route path="/reset-password/confirmation">
							<PasswordResentConfirmation />
						</Route>
						<Route path="/first">
							<FirstScreen />
				 		</Route>
						<Route path="/password-reset">
							<ResetPassword />
						</Route>
						<Route path="/">
							<Home />
						</Route>
					</Switch>
				</div>
			</Router>
		</main>
	);
}

function Home() {
	return <HomeComponent />;
}

function About() {
	return <AboutComponent />;
}

function Build() {
	return <BuildComponent />;
}

function Start() {
	return <StartComponent />;
}

function Signup() {
	return <SignupComp />;
}

function Login() {
	return <LoginComp />;
}

function ResetPasswordRequest() {
	return <ResetPasswordRequestComp />;
}

function PasswordResentConfirmation() {
	return <PasswordResentConfirmationComp />;
}

function FirstScreen() {
	return <FirstScreenComponent />;
}

function ResetPassword() {
	return <ResetPasswordComp />;
}
