import uuid from "react-uuid";
import { ADD_EXERCISE, REMOVE_EXERCISE, DELETE_EXERCISE, EDIT_EXERCISE } from "./exerciseTypes";


const initialSate = {
    exercises: [
        {
            key: uuid(),
            exercise: "Bent over Row-Hold",
            duration: 30,
            repetition: 30,
            recoveryTime: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Pec Fly/Press Up-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Deadlift/Pullup-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Shoulder Press Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Arm Curl-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Tricep Press/Dip-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Forearm Curl-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Leg Squat-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Calf Raise-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Lower Abs Raise-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
        {
            key: uuid(),
            exercise: "Abs Crunch-Hold",
            duration: 30,
            recoveryTime: 30,
            repetition: 30,
            destinationRound: 1,
        },
    ]
}
const exerciseReducer = (state = initialSate, action) => {
    switch(action.type) {
        case ADD_EXERCISE:
            return {
                ...state,
                exercises: [...state.exercises, action.payload] //new exercises array 
            }
        case REMOVE_EXERCISE:
            return {
                ...state, 
                exercises: state.exercises.filter(exercise => exercise.key!== action.payload) // returns a new filtered exercises array
            }
            case DELETE_EXERCISE: 
            const filteredExercises = state.exercises.filter(exercise => exercise.key!== action.payload)
              return { 
               ...state, 
               exercises: filteredExercises
            }
            case EDIT_EXERCISE: {
                const index = state.exercises.findIndex(exercise => exercise.key !==                                                                        action.payload); //finding index of the item
                const newArray = [...state.exercises]; //making a new array
                newArray[index].completed = true//changing value in the new array
                return { 
                 ...state, //copying the orignal state
                 exercises: newArray, //reassingning exercises to new array
                }
               }

            default: return state
    }
}

export default exerciseReducer;