import React from "react";
import { BrowserRouter as Link } from "react-router-dom";
import { ClientUrl } from "../components/url";
import styles from "../styles/passwordresetconfirmation.module.scss";

function PasswordResentConfirmation() {
	return (
		<div className="container mx-auto">
			<div className={`${styles.header} ${styles.dontShow}`}>
				<Link to="/">
					<div className={`${styles.backDiv}`}>
						<img src={`${ClientUrl}/back.svg`} alt="back" />
					</div>
				</Link>
				<Link to="/">
					<div className={`${styles.brandLogo}`}>
						<img src={`${ClientUrl}/assymexlogo.svg`} alt="asymmexlogo" />
					</div>
				</Link>
			</div>
			<div className={`${styles.helloDiv}`}>
				<p className={`${styles.helloParagraph}`}></p>
				<p className={`${styles.openAccount}`}></p>
			</div>
			<div className={`${styles.helloDiv}`}>
				<p className={`${styles.helloParagraph}`}>Reset,</p>
				<p className={`${styles.openAccount}`}>
					A link to update your password has been sent to your email
				</p>
			</div>
			<div className={`${styles.legExercise}`}>
				<img src={`${ClientUrl}/legexercise.svg`} alt="leg exercise pic" />
			</div>
		</div>
	);
}

export default PasswordResentConfirmation;
