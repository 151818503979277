//imports
import React, { createRef, useState } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { addExercise } from "../redux";
import styles from "../styles/build.module.scss";
import { ClientUrl,} from "../components/url";

const Build = (props) => {

	const exercise = props.exercises; //select exercises from store
	console.log(exercise)
	//default exercises
	let displayExercise = [];

	//state
	const [newExercise, setExercise] = useState({
		routineName: "",
		exerciseName: "none",
		exerciseTime: 0,
		repetitionTime: 0,
		recoveryTime: 0,
		destinationRound: 1,
		buildedExerises: displayExercise,
		count: 5000,
		elementKey: uuid(),
		visibility: "public",
		playAudio: false,
	});

	function handleChange(e) {
		const { name, value } = e.target;
		setExercise((user) => ({ ...user, [name]: value }));
		console.log(name, value);
	}

	//refs
	const exerciseRef = createRef();

	//create exercise function
	const createExercise = (e) => {
		e.preventDefault();
		// //send exercise to the backend
		// Axios.defaults.withCredentials = true;
		// Axios.post(`${ServerUrl}/exercise`, {
		// 	key: exercise.elementKey,
		// 	routine: exercise.routineName,
		// 	exercise: exercise.exercise,
		// 	duration: exercise.exerciseTime,
		// 	repetion: exercise.repetitionTime,
		// 	recoveryTime: exercise.recoveryTime,
		// 	destinationRound: exercise.destinationRound,
		// 	date: new Date().toLocaleTimeString(),
		// 	count: exercise.count,
		// 	visibility: exercise.visibility
		// }).then((data) => {
		// 	console.log(data);
		// });

		//add exercise to an array
		props.addExercise(newExercise)
		displayExercise.push(exercise);
	};
	return (
		<div className="container mx-auto">
			<div className={`${styles.header} ${styles.dontShow}`}>
				<Link to="/">
					<div className={`${styles.backDiv} mx-7`}>
						<img
							src={`${ClientUrl}/back.svg`}
							alt="back"
							className={`${styles.backImage} my-2.5 mx-2.5`}
						/>
					</div>
				</Link>
				<Link to="/">
					<div className={`${styles.brandLogo} ml-40`}>
						<img src={`${ClientUrl}/assymexlogo.svg`} alt="asymmexlogo" />
					</div>
				</Link>
			</div>
			<div className={`${styles.createDiv} mx-4`}>
				<p className={`${styles.createText}`}>Create Your Workout Timer</p>
			</div>
			<form action="/" onSubmit={createExercise}>
				<div className="form-group">
					<input
						className={`${styles.exerciseName} mx-4 `}
						ref={exerciseRef}
						name="routineName"
						id="routine"
						placeholder="routine name"
						list="routines"
						onChange={handleChange}
					/>
					<datalist id="routine">
						<option value="Full body exercise routine" />
						<option value="Leg exercise routine" />
						<option value="Chest exercise routine" />
						<option value="Arms exercise routine" />
					</datalist>
				</div>

				<div>
					<input
						className={`${styles.exerciseTime} mx-4 `}
						ref={exerciseRef}
						name="exerciseName"
						id="exercise"
						placeholder="exercise name"
						list="exercises"
						onChange={handleChange}
					/>
					<datalist id="exercises">
						<option value="Bent over Row-Hold" />
						<option value="Pec Fly/Press Up-Hold" />
						<option value="Deadlift/Pullup-Hold" />
						<option value="Shoulder Press Hold" />
						<option value="Arm Curl-Hold" />
						<option value="Tricep Press/Dip-Hold" />
						<option value="Forearm Curl-Hold" />
						<option value="Leg Squat-Hold" />
						<option value="Calf Raise-Hold" />
						<option value="Lower Abs Raise-Hold" />
					</datalist>
				</div>

				<div>
					<input
						type="text"
						name="exerciseTime"
						placeholder="exercise time"
						id="duration"
						list="exercise-duration"
						onChange={handleChange}
						className={`${styles.exerciseTime} mx-4`}
					/>
					<datalist id="exercise-duration">
						<option value="15" />
						<option value="20" />
						<option value="25" />
						<option value="30" />
						<option value="35" />
						<option value="40" />
						<option value="45" />
						<option value="50" />
						<option value="55" />
						<option value="60" />
						<option value="65" />
						<option value="70" />
						<option value="75" />
						<option value="80" />
					</datalist>
				</div>

				<div className="form-group">
					<input
						type="text"
						name="repetitionTime"
						placeholder="repetition"
						id="repetition"
						list="repetition-duration"
						onChange={handleChange}
						className={`${styles.exerciseRepetition} mx-4`}
					/>
					<datalist id="repetition-duration">
						<option value="15" />
						<option value="20" />
						<option value="25" />
						<option value="30" />
						<option value="35" />
						<option value="40" />
						<option value="45" />
						<option value="50" />
						<option value="55" />
						<option value="60" />
						<option value="65" />
						<option value="70" />
						<option value="75" />
						<option value="80" />
					</datalist>
				</div>
				<div className="form-group">
					<input
						type="number"
						name="recoveryTime"
						placeholder="recovery time"
						id="recovery-duration"
						list="recovery-list"
						onChange={handleChange}
						className={`${styles.exerciseRecoveryTime} mx-4`}
					/>
					<datalist id="recovery-list">
						<option value="15" />
						<option value="20" />
						<option value="25" />
						<option value="30" />
						<option value="35" />
						<option value="40" />
						<option value="45" />
						<option value="50" />
						<option value="55" />
						<option value="60" />
						<option value="65" />
						<option value="70" />
						<option value="75" />
						<option value="80" />
					</datalist>
				</div>

				<div className="form-group">
					<input
						type="text"
						name="destinationRound"
						placeholder="Select circuit number"
						id="round"
						list="destination-rounds"
						onChange={handleChange}
						className={`${styles.circuit} mx-4`}
					/>
					<datalist id="destination-rounds">
						<option value="1" />
						<option value="2" />
						<option value="3" />
						<option value="4" />
						<option value="5" />
						<option value="6" />
					</datalist>
				</div>

				<div className="form-group">
					<select
						name="visiility"
						placeholder="Select visibility"
						id="round"
						list="destination-rounds"
						onChange={handleChange}
						className={`${styles.circuit2} mx-4`}>
						<option value="public">Public</option>
						<option value="private">Private</option>
					</select>
				</div>

				<div className={`${styles.saveDiv} `}>
					<p className={`${styles.saveParagraph}`}>Save exercise</p>
					<button type="submit" className={`${styles.saveButton}`}>
						<img
							src={`${ClientUrl}/done.svg`}
							alt="save"
							className={`${styles.imageButton} mx-2.5 my-2.5`}
						/>
					</button> 
				</div>
				<Link to="/start">
					<div className={`${styles.submitParagraph} `}>
						<p className={`${styles.letsContinue}`}>Start exercise</p>
						<button type="submit" className={`${styles.submitButton}`}>
							<img
								src={`${ClientUrl}/Vector.svg`}
								alt="continue"
								className={`${styles.imageButton} mx-3 my-3`}
							/>
						</button>
					</div>
				</Link>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		exercise: state.exercises
	}
}

const mapDispatchToProps = (dispatch) => {
	return {

		addExercise: (newExercise) => dispatch(addExercise(newExercise))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Build)