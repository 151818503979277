
import { ADD_EXERCISE, REMOVE_EXERCISE, DELETE_EXERCISE, FETCH_EXERCISE_REQUEST, FETCH_EXERCISE_SUCCESS, FETCH_EXERCISE_FAILURE, EDIT_EXERCISE } from "./exerciseTypes";

export const addExercise = (newExercise) => {
    return {
        type: ADD_EXERCISE,
        payload: newExercise
    }
};

export const removeExercise = (exercise) => {
    return {
        type: REMOVE_EXERCISE,
        payload: exercise
    }
};

export const deleteExercise = (exerciseKey) => {
    return {
        type: DELETE_EXERCISE,
        payload: exerciseKey
    }
}

export const editExercise = (exerciseKey) => {
    return {
        type: EDIT_EXERCISE,
        payload: exerciseKey
    }
}

export const fetchUsersRequest = () => {
    return {
        type: FETCH_EXERCISE_REQUEST
    }
}
export const fetchUsersSuccess = (exercise) => {
    return {
        type: FETCH_EXERCISE_SUCCESS,
        payload: exercise
    }
}
export const fetchUsersFailure = (error) => {
    return {
        type: FETCH_EXERCISE_FAILURE,
        payload: error
    }
}

