import React from "react";
import { Link } from "react-router-dom";
import {ClientUrl} from '../components/url';
import styles from "../styles/home.module.scss";


//aka welcome 
function Home() {
  
  return (
    <div className="container mx-auto">
      <header className = {`${styles.brandLogo} mx-8`} >
        <img src={`${ClientUrl}/logo.svg`} alt="logo" className={`${styles.dontShow} `}/>
        <p className={styles.introText}>Build your exercise and start training</p>
      </header>

      <div className={`${styles.icRun} mx-28 sm:mx-30 md:mx-40`}>
        <img src={`${ClientUrl}/ic_Run.svg`} alt="run"  className= {styles.icRunImage}/>
      </div>

      <Link to="/build">
      <div className={`${styles.buildDiv}  mx-7`}>
      <p className={`${styles.buildParagraph}`}>Build exercise</p>
      <button className={`${styles.buildButton}`}>
      <img src={`${ClientUrl}/plus.svg`} alt="plus" className={`${styles.imageButton} mx-3 my-3 `}/>
        </button>
      </div>
      </Link>
 
      <Link to="/signup">
      <div className={`${styles.signupDiv}  mx-7`}>
      <p className={`${styles.signupParagraph}`}>Open an account</p>
      <button className={`${styles.signupButton}`}> 
      <img src={`${ClientUrl}/Vector.svg`} alt="go" className={`${styles.imageButton} mx-3 my-3`}/>
        </button>
      </div>
      </Link>

      <div className= {`${styles.loginDiv} ${styles.dontShow}`}>
        <p className={`${styles.loginParagraph}`}> Have an account <Link to="/login" > <span className={`${styles.loginText}`}> Log in</span> </Link></p>
      </div>
    </div>
  ); 
}

export default Home;
